
import PartnerCard from "@/components/region/PartnerCard"
export default {
    name: "PartnerCardBlok",
    components: { PartnerCard },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
}
